<template>
  <div>
    <a-modal
      :visible="showModal"
      @cancel="clickShowModal"
      :title="'Detil Pencatatan Gaji'"
      :width="900"
      centered
    >
      <ModalRincianGaji :dataDetil="dataDetil" showBio />
      <template slot="footer">
        <a-button class="d-none" key="back"></a-button>
        <a-button class="d-none" key="submit"></a-button>
      </template>
    </a-modal>
    <div class="mb-4">STATUS PERIODE: {{periodStatus ? periodStatus.toUpperCase() : '-'}}</div>
    <div class="d-flex align-items-center">
      <div class="mr-3">
        <a-input :disabled="periodStatus === 'berjalan'" @change="searchPegawai" size="large" placeholder="Cari pegawai..." />
      </div>
      <div class="mr-3">
        <a-select size="large" class="w-100" @change="onSelectChange" v-model="selectMonth">
          <template v-for="(item) in months">
            <a-select-option v-if="selectYear === item.tahun" :key="item.id" :value="item.id">
              {{item.bulan}}
            </a-select-option>
          </template>
        </a-select>
      </div>
      <div class="mr-3">
        <a-select size="large" class="w-100" @change="onSelectChange('tahun')" v-model="selectYear">
          <a-select-option v-for="(item, index) in years" :key="index" :value="item">
            {{item}}
          </a-select-option>
        </a-select>
      </div>
      <div class="mr-3">
        <a-select
          size="large"
          v-model="selectUnit"
          @change="onSelectChange"
          :disabled="periodStatus === 'berjalan'"
        >
          <a-select-option key="all">Semua Unit</a-select-option>
          <a-select-option v-for="unit in units" :key="unit.id" :value="unit.id">{{unit.nama}}</a-select-option>
        </a-select>
      </div>
      <div class="ml-auto">
        <a-button :disabled="periodStatus === 'berjalan'" size="large" @click.prevent="syncData"><a-icon type="sync" />Sinkronkan</a-button>
      </div>
      <!-- <div class="ml-3">
        <a-button size="large" type="primary" @click.prevent="printCsv"><a-icon type="login" />Cetak</a-button>
      </div> -->
      <div class="ml-3">
        <a-button :disabled="finalizeButtonIsDisabled"  size="large" type="primary" @click.prevent="handleFinalisasiPenggajian"><a-icon type="login" />Finalisasi Penggajian</a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        @change="handleTableChange"
        :columns="gajiColumns"
        :loading="loading.table"
        :pagination="pagination"
        :data-source="dataTable" bordered
        :locale="{
          emptyText: cobaEmpty,
        }"
      >
        <!-- <template slot="title">
          UNIT (SEMUA)
        </template> -->
        <div slot="name" slot-scope="value" class="d-flex align-items-center">
          <!-- <a-avatar class="bg-primary mr-2">
            J
          </a-avatar> -->
          {{value}}
        </div>
        <div slot="jabatan" slot-scope="value, record">
          <template v-if="!record.jabatan.length">-</template>
          <template v-else>
            <a-tag
                v-for="obj in record.jabatan" :key="obj.id"
              >
              {{obj.jabatan.nama}}
            </a-tag>
          </template>
        </div>
        <div slot="status" slot-scope="value, record">
          <a-tag class="payment-status" @click.prevent="changePaymentStatus(record)" :color="value === 'paid' ? 'green' : 'red'">
            {{value === 'paid' ? 'TERBAYAR' : 'BELUM TERBAYAR'}}
          </a-tag>
        </div>
        <div slot="gaji" slot-scope="value">
          {{thousandPrice(Number(value))}}
        </div>
        <!-- <span slot="gaji" slot-scope="value">
          {{thousandPrice(value)}}
        </span>
        <span slot="status" slot-scope="value">
          <a-tag
            :color="value === 'Sudah Dibayar' ? 'green' : value === 'Belum Dibayar' ? 'volcano' : ''"
          >
            {{ value.toUpperCase() }}
          </a-tag>
        </span> -->
        <div class="d-flex flex-column" slot="aksi" slot-scope="value, record">
          <a-button @click.prevent="clickShowModal(record)" size="large" class="w-100"><a-icon type="wallet" />Detil</a-button>
          <a-button v-if="periodStatus === 'terfinalisasi'" @click.prevent="generateReport(record)" size="large" class="mt-3 border-danger text-danger w-100"><a-icon type="file-pdf" />Slip Gaji</a-button>
          <!-- <a-button size="large" type="danger"><a-icon type="file-pdf" />Export Payslip</a-button> -->
        </div>
      </a-table>
    </div>
    <vue-html2pdf
      :html-to-pdf-options="pdfOptions"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="930"
      filename="hahaha"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
      @beforeDownload="beforeDownload($event)"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <slip-gaji :configSchool="configSchool" :slipGaji="slipSelected" />
      </section>
    </vue-html2pdf>
  </div>
</template>
<script>
import config from '@/config'
import thousandPrice from '@/helpers/thousandPrice'
import VueHtml2pdf from 'vue-html2pdf'
import ExcelJs from 'exceljs'
const ModalRincianGaji = () => import('@/components/app/AdminKeuangan/ModalRincianGaji')
const SlipGaji = () => import('@/components/app/Shared/SlipGaji')

const gajiColumns = [
  {
    title: 'Nama Pegawai',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Jabatan',
    dataIndex: 'jabatan',
    key: 'jabatan',
    align: 'center',
    scopedSlots: { customRender: 'jabatan' },
  },
  {
    title: 'Gaji yang Diterima (Rp)',
    dataIndex: 'gaji',
    key: 'gaji',
    align: 'right',
    scopedSlots: { customRender: 'gaji' },
  },
  {
    title: 'Status Gaji',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: 'Aksi',
    key: 'aksi',
    dataIndex: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
const gajiData = [
  {
    key: 1,
    nama: 'Supriadi, SE',
    gaji: 10250000,
    jabatan: 'Wakil Ketua LPI',
    status: 'Sudah Dibayar',
  },
  {
    key: 2,
    nama: 'Nurlailah, S.Pd',
    gaji: 9250000,
    jabatan: 'Kepala Sekolah KB-TK',
    status: 'Belum Dibayar',
  },
  {
    key: 3,
    nama: 'Aang Kunaifi, S.Sos.I',
    gaji: 9250000,
    jabatan: 'Kepala Sekolah SD',
    status: 'Belum Dibayar',
  },
  {
    key: 4,
    nama: 'Ade Badar Taufiq, ST',
    gaji: 9250000,
    jabatan: 'Kepala Sekolah SMP',
    status: 'Sudah Dibayar',
  },
]

const dataSlipGaji = {
  pegawai: {
    nik: 811015161,
    nama: 'Ade Badar',
    jenis_kelamin: 'Laki-Laki',
    status_pegawai: 'Tetap',
    jabatan_pegawais: [
      {
        jabatan: {
          nama: 'Kepala Sekolah',
          jabatan_tunjangan: {
            tunjangan_fungsional: 7,
            tunjangan_jabatan: 0,
            tunjangan_lain: 0,
            tunjangan_operasional: 0,
            tunjangan_transportasi: 23000,
            tunjangan_makan: 23000,
          },
        },
      },
      // {
      //   jabatan: {
      //     nama: 'Kepala Sekolah',
      //     jabatan_tunjangan: {
      //       tunjangan_fungsional: 45,
      //       tunjangan_jabatan: 45,
      //       tunjangan_lain: 0,
      //       tunjangan_operasional: 500000,
      //       tunjangan_transportasi: 45000,
      //       tunjangan_makan: 45000,
      //     },
      //   },
      // },
      // {
      //   jabatan: {
      //     nama: 'Guru Kelas VII',
      //     jabatan_tunjangan: {
      //       tunjangan_fungsional: 7,
      //       tunjangan_jabatan: 0,
      //       tunjangan_lain: 0,
      //       tunjangan_operasional: 0,
      //       tunjangan_transportasi: 45000,
      //       tunjangan_makan: 45000,
      //     },
      //   },
      // },
    ],
    unit: {
      nama: 'SMP',
    },
    golongan: {
      nama: '3C',
      gaji_pokok: 1100000,
    },
    // marital_tunjangan: {
    //   status_marital: 'M2',
    //   laki_laki: 6,
    //   perempuan: 3,
    //   tunjangan_keluarga: 12,
    //   tunjangan_kepala_keluarga: 10,
    // },
    marital_tunjangan: null,
  },
  rekap_absen: {
    periode: {
      tanggal_awal: '2021-02-26',
      tanggal_akhir: '2021-03-25',
    },
    rincian_gaji: {
      tanggal_pembayaran_gaji: '2021-03-17 12:00',
      total_penghasilan: 0,
      total_pengurangan: 0,
      total_gaji: 0,
    },
    sakit: 0,
    izin: 0,
    alfa: 0,
    cuti: 0,
    libur: 1,
    terlambat: 0,
    pulang_lebih_awal: 0,
    hari_kerja_normal: 22,
    jumlah_hari_kerja: 14,
    tambahan_hari_kerja: 0,

  },
}

export default {
  data() {
    return {
      gajiColumns,
      gajiData,
      dataSlipGaji,
      slipSelected: {},
      loading: {
        table: false,
      },
      pdfOptions: {
        margin: [5, 5, 5, 5],
        filename: '',
        image: {
          type: 'jpeg',
          quality: 0.50,
        },
        html2canvas: {
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          // unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      },
      selectMonth: null,
      selectYear: null,
      configSchool: null,
      selectUnit: 'all',
      units: [],
      months: [],
      years: [],
      pagination: {},
      thousandPrice,
      visibleModalExportRecapitulation: false,
      dataTable: [],
      periodStatus: null,
      showModal: false,
      dataDetil: {},
      idPeriode: null,
      finalizeButtonIsDisabled: true,
    }
  },
  components: {
    ModalRincianGaji,
    VueHtml2pdf,
    SlipGaji,
  },
  created() {
    this.loading.table = true
    this.configSchool = config.school
    this.$store.dispatch('absensiKaryawan/FETCH_PERIODE_KEHADIRAN', { page: 'all' })
      .then(datas => {
        // console.log(datas)
        // this.column
        if (datas.length) {
          this.selectMonth = datas[0].id
          this.selectYear = datas[0].tahun
        }
        datas.forEach(periode => {
          if (!this.years.includes(periode.tahun)) this.years.push(periode.tahun)
        })
        // const dataTahun = datas.filter(data => { return !this.years.includes(data.tahun) })
        // this.years = dataTahun.map(data => { return data.tahun })
        this.months = datas.map(data => { return { id: data.id, bulan: data.bulan, tahun: data.tahun } })
        return this.$store.dispatch('absensiKaryawan/FETCH_ALL_UNIT')
      })
      .then(datas => {
        console.log(datas)
        this.units = datas.map(data => { return { id: data.id, nama: data.nama } })
        return this.syncData()
      })
  },
  methods: {
    async printCsv() {
      try {
        const workbook = new ExcelJs.Workbook()
        workbook.created = new Date()
        const bulan = this.months.find(month => { return month.id === this.selectMonth })
        const sheet = workbook.addWorksheet(bulan.bulan)
        sheet.getCell('A1').value = 'tes'
        // sheet.addTable({
        //   name: 'MyTable',
        //   ref: 'A1',
        //   // headerRow: true,
        //   // totalsRow: true,
        //   style: {
        //     theme: 'TableStyleDark3',
        //     showRowStripes: true,
        //   },
        //   columns: [
        //     { name: 'Date', totalsRowLabel: 'Totals:', filterButton: true },
        //     { name: 'Amount', totalsRowFunction: 'sum', filterButton: false },
        //   ],
        //   rows: [
        //     [new Date('2019-07-20'), 70.10],
        //     [new Date('2019-07-21'), 70.60],
        //     [new Date('2019-07-22'), 70.10],
        //   ],
        // })

        await workbook.csv.writeFile('filename')
      } catch (err) {
        console.log(err)
      }
    },
    changePaymentStatus(data) {
      if (this.finalizeButtonIsDisabled) {
        this.$notification.error({
          message: 'Maaf',
          description: 'Tidak dapat mengubah status gaji yang sudah terfinalisasi',
        })
      } else {
        this.$confirm({
          title: 'Peringatan',
          content: (
            <div>
              <div>Apa anda yakin untuk mengubah status gaji menjadi "{data.status === 'paid' ? 'BELUM TERBAYAR' : 'TERBAYAR' }"?</div>
            </div>
          ),
          onOk: () => {
            this.loading.table = true
            this.$store.dispatch('adminHR/UPDATE_PAYMENT_STATUS', { id: data.id })
              .then(isSuccess => {
                if (isSuccess) {
                  this.$notification.success({
                    message: 'Sukses.',
                    description: 'Berhasil mengubah status gaji',
                  })
                } else {
                  this.$notification.error({
                    message: 'Maaf',
                    description: 'Terjadi kesalahan pada internal server.',
                  })
                }
                this.fetchDataTable({ search: this.searchData })
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Lanjutkan',
          cancelText: 'Batalkan',
        })
      }
      console.log('payment', data)
    },
    generateReport(data) {
      // this.slipSelected = dataSlipGaji
      this.slipSelected = data.rekap_absens[0].rincian_gaji.metadata
      // console.log(this.slipSelected)
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress(event) {
      console.log('Progress  ', event)
    },
    hasStartedGeneration() {
      console.log('Start Generate')
    },
    hasGenerated(event) {
      console.log('Generated', event)
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      this.pdfOptions.filename = `${this.slipSelected.pegawai.nama.split(',')[0]} SLIP GAJI`
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        // const totalPages = pdf.internal.getNumberOfPages()
        // for (let i = 1; i <= totalPages; i++) {
        //   pdf.setPage(i)
        //   pdf.setFontSize(10)
        //   pdf.setTextColor(150)
        //   pdf.text('Hasan Educational Technology', (pdf.internal.pageSize.getWidth() * 0.50), (pdf.internal.pageSize.getHeight() - 2))
        //   pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 2))
        // }
      }).save()
    },
    cobaEmpty() {
      return (
        <a-empty class="p-4">
          <div slot="description">
            <span class="font-size-18" style="color: #0000009C">Absensi Sedang Berjalan</span>
            <br />
            <span style="color: #7474747a">Hubungi Bagian SDM / HR untuk masuk dalam fasee pengecekan dan dapat melihat rincian gaji.</span>
          </div>
        </a-empty>
      )
    },
    clickShowModal(data) {
      // console.log(data)
      if (this.showModal) {
        this.showModal = !this.showModal
        return
      }// kalau keadaan awal terbuka
      // console.log(data)
      this.$store.dispatch('adminKeuangan/FETCH_DETAIL_PENCATATAN_GAJI', { idRincianGaji: data.idRincianGaji, idPeriod: this.selectMonth })
        .then(dataDetil => {
          console.log(data)
          this.dataDetil = {
            ...data,
            ...dataDetil,
          }
          // console.log(this.dataDetil)
        })

      setTimeout(() => {
        this.showModal = !this.showModal
      }, 400)
    },
    handleMenuClick(e) {
      // console.log(e)
      if (e.key === 'payroll') return this.runPayroll()
      else if (e.key === 'sync') return this.syncData()
    },
    runPayroll() {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>
            <div>Apa anda yakin untuk mengirimkan data rekap pada bagian keuangan?</div>
            <small>*Selanjutnya akan dilakukan koordinasi pengecekan terhadap bagian keuangan dan SDM.</small>
          </div>
        ),
        onOk: () => {
          this.loading.table = true
          this.$store.dispatch('adminHR/FINALIZE_ABSENT_RECAP', { idPeriod: this.selectMonth })
            .then(isSuccess => {
              if (isSuccess) {
                this.$notification.success({
                  message: 'Sukses.',
                  description: 'Berhasil mengubah status periode menjadi pengecekan',
                })
              } else {
                this.$notification.error({
                  message: 'Maaf',
                  description: 'Terjadi kesalahan pada internal server.',
                })
              }
              this.fetchDataTable({ search: this.searchData })
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Lanjutkan',
        cancelText: 'Batalkan',
      })
    },
    syncData() {
      this.loading.table = true
      // this.fetchDataTable({ search: this.searchData })
      if (this.periodStatus === 'terfinalisasi') return this.fetchDataTable({ search: this.searchData })
      this.$store.dispatch('adminKeuangan/SYNC_RINCIAN_GAJI_RECAP', { idPeriod: this.selectMonth })
        .then(_ => {
          this.fetchDataTable({ search: this.searchData })
        })
    },
    searchPegawai(e) {
      // console.log(value)
      this.searchData = e.target.value
      this.fetchDataTable({ search: this.searchData })
    },
    onSelectChange(status) {
      if (status === 'tahun') {
        const dataMonth = this.months.filter(month => month.tahun === this.selectYear)
        this.selectMonth = dataMonth[0].id
      }
      this.fetchDataTable()
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      let order = ''
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchDataTable({
        page: pagination.current,
        order: order,
        search: this.searchData,
      })
    },
    fetchDataTable(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loading.table = true
      this.$store.dispatch('adminKeuangan/FETCH_ALL_PAYROLL_RECAP_BY_PERIOD', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy, idPeriod: this.selectMonth, unit: this.selectUnit })
        .then(({ data, total, periode }) => {
          // console.log('data, total, periode :>> ', data, total, periode)
          this.idPeriode = periode.id
          this.periodStatus = periode.status_periode
          if (this.periodStatus === 'pengecekan') {
            this.finalizeButtonIsDisabled = false
          }
          this.loading.table = false
          const pagination = { ...this.pagination }
          this.pagination.total = total
          pagination.total = total
          this.pagination = pagination
          // console.log(data)
          this.dataTable = data.map(el => {
            const rekapAbsen = this.periodStatus === 'terfinalisasi' ? el.rekap_absens[0].rincian_gaji.metadata.rekap_absen : el.rekap_absens[0]
            const dataRincian = {
              idRincianGaji: rekapAbsen.rincian_gaji.id,
              status: rekapAbsen.rincian_gaji.status_gaji,
              gaji: rekapAbsen.rincian_gaji.total_gaji,
            }
            return {
              ...el,
              key: el.id,
              id: el.id,
              nama: el.nama,
              nik: el.nik,
              idPeriod: periode.id,
              unit: el.unit ? el.unit.nama : null,
              jabatan: el.jabatan_pegawais,
              idRekapAbsen: rekapAbsen.id,
              golongan: el.golongan ? el.golongan.nama : null,
              ...dataRincian,
            }
          })
        })
    },
    toggleModalExport() {
      this.visibleModalExportRecapitulation = !this.visibleModalExportRecapitulation
    },
    periodOnChange(value) {
      console.log('ok')
      this.periodData = value
    },
    handleOkModalExport() {
      console.log('export')
    },
    handleFinalisasiPenggajian () {
      const id = this.selectMonth
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>
            <div>Apa anda yakin untuk memfinalisasi keuangan & absensi pegawai pada periode ini?</div>
            <small>Pastikan data absensi dan keuangan pada setiap pegawai telah benar, dan anda telah membayar semua pegawai sesuai rincian gaji masing - masing pegawai</small>
          </div>
        ),
        onOk: () => {
          this.loading.table = true
          this.finalizeButtonIsDisabled = true
          this.$store.dispatch('adminKeuangan/UPDATE_RINCIAN_GAJI_TO_FINALISASI', { id })
            .then(res => {
              this.loading.table = false
              if (res.isSuccess) {
                this.$notification.success({
                  message: 'SUKSES',
                  description: 'Berhasil memfinalisasi keuangan & absensi pegawai untuk periode ini',
                })
              } else {
                this.$notification.error({
                  message: 'ERROR',
                  description: 'Gagal memfinalisasi keuangan & absensi pegawai untuk periode ini!',
                })
              }
              this.fetchDataTable({ search: this.searchData })
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Lanjutkan',
        cancelText: 'Batalkan',
      })
    },
  },
}
</script>
<style lang="css" scoped>
.payment-status {
  cursor: pointer;
}
</style>
